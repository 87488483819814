import React from 'react';
import styled from 'styled-components';
import {Container} from './ui/Container';
import img1 from './../img/services/1.png'
import img2 from './../img/services/2.png'
import img3 from './../img/services/3.png'
import img4 from './../img/services/4.png'
import img5 from './../img/services/5.png'
import img6 from './../img/services/6.png'
import variable from "./../style/var.module.scss"
import {H20} from "./ui/H20";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ServicesContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: grid;
      grid-template-columns: repeat(3, 275px);
      grid-gap: 60px;

      > a {
        border-radius: 20px;
        height: 300px;
        padding: 40px;
        box-shadow: 0 24px 106px 0 rgba(26, 70, 125, 0.17);
        cursor: pointer;

        &:hover {
          > span {
            box-shadow: 0 10px 60px 0 rgba(26, 70, 125, 0.40);
            transition: all .3s ease;
            transform: scale(1.05);
          }
        }

        > span {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(180deg, ${variable.BLUE_COLOR_TRANSPARENT}, ${variable.BLUE_COLOR});
          box-shadow: 0 0 0 10px rgba(26, 70, 125, 0.12);
          transition: all .3s ease;
          transform: scale(1);

          > img {
            height: 52px;
          }
        }

        > h3 {
          margin-top: 30px;
        }

      }
    }
  }

  @media (max-width: 1042px) {
    > div {
      > div {
        grid-template-columns: repeat(2, 275px);
      }
    }
  }

  @media (max-width: 712px) {
    > div {
      > div {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  @media (max-width: 546px) {
    > div {

      > div {

        > a {
          border-radius: 20px;
          height: auto;
          padding: 40px;
          box-shadow: 0 24px 56px 0 rgba(26, 70, 125, 0.17);
          cursor: pointer;
        }

      }
    }
  }
}

@media (max-width: 400px) {
  > div {
    > div {
      grid-gap: 30px;
    }
  }
}
`;


const services = [
    {id: 1, name: 'Услуги складирования', icon: img1, path: '/warehousing'},
    {id: 2, name: 'Внутренние автоперевозки', icon: img2, path: '/domestic-trucking'},
    {id: 3, name: 'Международные автоперевозки', icon: img3, path: '/international-trucking'},
    {id: 4, name: 'Мультимодальные перевозки', icon: img4, path: '/multimodal-transportation'},
    {id: 5, name: 'Брокерские услуги', icon: img5, path: '/brokerage'},
    {id: 6, name: 'Технический импорт - экспорт', icon: img6, path: '/technical-import-export'}
]


export const Services = () => {
    const {t} = useTranslation('global')



    return <ServicesContainer data-aos="fade-up">
        <Container pTop>
            <div>
                {services.map((el, idx) => <NavLink key={idx + 1} to={el.path}>
                    <span><img src={el.icon} alt=""/></span>
                    <H20 align="center" weight>{t(`services.${idx}.title`)}</H20>
                </NavLink>)}
            </div>
        </Container>
    </ServicesContainer>
}
