import React from 'react';
import styled, {css} from 'styled-components'


const ContainerStyled = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 30px;

  @media(max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  ${props => props.pTop && css`
    padding-top: 80px;
    padding-bottom: 80px;

    @media(max-width: 564px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  `}
`


export const Container = ({children, ...rest}) => <ContainerStyled {...rest}>
    {children}
</ContainerStyled>

