import React from 'react';
import styled, {css} from "styled-components";
import variable from "./../../style/var.module.scss"


const ButtonStyled = styled.button`
  border: none;
  outline: none;
  padding: 22px 43px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;


  @media(max-width: 900px) {
    font-size: 16px;
    padding: 18px 39px;
  }


  ${props => props.linear && css`
    background: linear-gradient(180deg, ${variable.BLUE_COLOR_TRANSPARENT}, ${variable.BLUE_COLOR});
    color: #fff;
    box-shadow: 0 17px 55px 0 rgba(26, 70, 125, 0.25);
    
    &:active {
      background: linear-gradient(0deg, ${variable.BLUE_COLOR_TRANSPARENT}, ${variable.BLUE_COLOR});
    }
  `}
  
  
`

export const Button = ({children, ...rest}) => <ButtonStyled {...rest}>{children}</ButtonStyled>
