import React from 'react';
import {Banner} from './Banner';
import {Navbar} from './Navbar';
import styled from "styled-components";
import air from "./../../img/air.png"


const HeaderStyled = styled.div`
  position: relative;
  background: #fff;
  > div {
    border-bottom-right-radius: 97%;
    border-bottom-left-radius: 97%;
    overflow: hidden;
    box-shadow: inset 0 -4px 24px 0 rgba(189, 189, 189, 0.24);
  }

  > img {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  @media(max-width: 1172px) {
    > div {
      border-bottom-right-radius: 47%;
      border-bottom-left-radius: 47%;
    }

    > img {
      width: 55%;
    }
  }
  
  @media(max-width: 564px) {
    > div {
      border-bottom-right-radius: 250px;
      border-bottom-left-radius: 250px;
    }
  }
`

const Header = () => {
    return <HeaderStyled>
        <div>
            <Navbar/>
            <Banner/>
        </div>
        <img src={air} alt=""/>
    </HeaderStyled>
}

export default Header;
