import React from 'react';
import styled from 'styled-components';
import {Container} from '../ui/Container';
import {H56} from "../ui/H56";
import {Button} from "../ui/Button";
import mapImage from "./../../img/map.png"
import {H20} from "../ui/H20";
import {scroller} from "react-scroll";
import {useTranslation} from "react-i18next";

const BannerBlock = styled.div`
  padding-top: 80px;

  > div {
    text-align: center;
    position: relative;

    > h3 {
      margin-top: 30px;
    }

    > button {
      margin-top: 40px;
      position: relative;
      z-index: 1;
    }

    > img {
      width: 100%;
      margin-top: -70px;
    }
  }

  @media (max-width: 900px) {
    padding-top: 30px;
    
    > div {
      > h3 {
        margin-top: 20px;
      }
      
      > button {
        margin-top: 30px;
      }
      
      > img {
        margin-top: -59px;
      }
    }
  }


  @media (max-width: 653px) {
    > div {
      > img {
        margin-top: -17px;
      }
    }
  }


  @media (max-width: 564px) {
    > div {
      > h3 {
        margin-top: 10px;
      }

      > button {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 400px) {
    > div {
      > img {
        margin-top: 0;
      }
    }
  }
`;

export const Banner = () => {
    const {t} = useTranslation('global')

    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    return <BannerBlock>
        <Container>
            <H56 data-aos="zoom-in">{t("header.title")}</H56>
            <H20 data-aos="fade-up" align="center" greyColor>{t("header.desc")}</H20>
            <Button linear onClick={() => scrollTo("contacts")}>{t("button")}</Button>
            <img src={mapImage} alt=""/>
        </Container>
    </BannerBlock>
}
