import React, {useEffect, useState} from 'react';
import {Button} from "./ui/Button";
import styled from "styled-components";
import {Card} from "./ui/Card";
import {Container} from "./ui/Container";
import {useTranslation} from "react-i18next";
import Modal from 'react-awesome-modal';
import {H20} from "./ui/H20";
import {IoCheckmarkDoneCircleOutline} from "@react-icons/all-files/io5/IoCheckmarkDoneCircleOutline";
import variable from "./../style/var.module.scss"


const FormStyled = styled.div`
  > div {
    max-width: 824px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 30px;

      > input,
      > textarea {
        border-radius: 10px;
        border: none;
        outline: none;
        background: #E9EDF5;
        padding: 21px;
        font-size: 18px;

        @media (max-width: 900px) {
          font-size: 16px;
        }
      }

      > textarea {
        resize: none;
        grid-column: 1 / -1;
        height: 160px;
      }

      > button {
        grid-column: 1 / -1;
      }


      @media (max-width: 623px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }
  }
`
const FormStyledModal = styled.div`
  padding: 30px;
  text-align: center;

  > svg {
    font-size: 80px;

    > path {
      color: ${variable.BLUE_COLOR};
    }
  }

  > h3 {
    margin-top: 10px;
    max-width: 400px;
    color: #828282;
  }
`

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const Form = () => {
    const {t} = useTranslation('global')


    const [formValue, setFormValue] = useState({
        fullName: '',
        email: '',
        message: ''
    })
    const [isValidateEmail, setIsValidateEmail] = useState(false)
    const [isInitFullName, setIsInitFullName] = useState(false)
    const [isInitEmail, setIsInitEmail] = useState(false)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        window.addEventListener('click', () => {
            !isInitFullName && setIsInitFullName(false)
            !isInitEmail && setIsInitEmail(false)
        })
    }, []);


    const handlerSubmit = () => {
        !isInitFullName && setIsInitFullName(true)
        !isInitEmail && setIsInitEmail(true)

        if (formValue.fullName && isValidateEmail) {
            fetch(`https://api.telegram.org/bot5173144770:AAHUXVRDW2_JXUfuRKeDyok8bc8PvIURSuY/sendMessage?chat_id=-1001798741733&text=${encodeURIComponent(`Полная имя: ${formValue.fullName} \nПочта: ${formValue.email} \nСообщение: ${formValue.message}`)}`)
                .then(response => response.text())
                .then(result => {
                    setFormValue({
                        fullName: '',
                        email: '',
                        message: ''
                    })
                    setIsValidateEmail(false)
                    setIsInitFullName(false)
                    setIsInitEmail(false)
                    setModal(true)
                })
                .catch(error => console.log('error', error));
        }
    }

    const handlerChange = (e) => {
        const {name, value} = e.target

        if (name === "email") {
            validateEmail(value) ? setIsValidateEmail(true) : setIsValidateEmail(false)
        }

        setFormValue(prev => ({
            ...prev,
            [name]: value
        }))
    }


    return <>

        <FormStyled>
            <Container data-aos="zoom-in">
                <Card onClick={(e) => e.stopPropagation()}>
                    <input
                        style={{background: (!formValue.fullName && isInitFullName) && '#ff00001f'}}
                        name="fullName"
                        onChange={handlerChange}
                        value={formValue.fullName}
                        type="text"
                        placeholder={t("fullName")}
                    />
                    <input
                        style={{background: (!isValidateEmail && isInitEmail) && '#ff00001f'}}
                        name="email"
                        onChange={handlerChange}
                        value={formValue.email}
                        type="email"
                        placeholder={t("email")}
                    />
                    <textarea
                        name="message"
                        value={formValue.message}
                        onChange={handlerChange}
                        placeholder={t("message")}
                    />
                    <Button onClick={handlerSubmit} linear>{t("button")}</Button>
                </Card>
            </Container>
        </FormStyled>
        <Modal
            visible={modal}
            effect="fadeInDown"
            onClickAway={() => setModal(false)}
        >
            <FormStyledModal>
                <IoCheckmarkDoneCircleOutline/>
                <H20 align="center">{t("dialog")}</H20>
            </FormStyledModal>
        </Modal>
    </>
}

