import React from 'react';
import styled from "styled-components";
import {Form} from "./Form";
import logoWhite from './../img/logoWhite.png'
import {Container} from "./ui/Container";
import {FaFacebook} from "@react-icons/all-files/fa/FaFacebook";
import {RiInstagramFill} from "@react-icons/all-files/ri/RiInstagramFill";
import {FaTelegram} from "@react-icons/all-files/fa/FaTelegram";
import {FaTwitter} from "@react-icons/all-files/fa/FaTwitter";
import {useTranslation} from "react-i18next";

const FooterStyled = styled.div`
  background: rgba(241, 247, 252, 0.78);

  > span {
    display: block;
    border-top-left-radius: 70%;
    border-top-right-radius: 70%;
    background: linear-gradient(180deg, #234F85, #000F22);
    margin-top: -60px;
    padding: 0 100px;

    @media (max-width: 1172px) {
      border-top-left-radius: 47%;
      border-top-right-radius: 47%;
    }

    @media (max-width: 1052px) {
      padding: 0 50px;
    }

    @media (max-width: 768px) {
      padding: 0 50px;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      > div {
        padding-top: 150px !important;
      }
    }

    @media (max-width: 564px) {
      padding: 0 0;
    }
    
    > div {
      padding-top: 180px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      grid-gap: 60px;

      > div {
        > h2 {
          padding-bottom: 10px;
          color: #fff;
          font-weight: 600;
          font-size: 16px;
        }

        > p, > a {
          display: block;
          margin-top: 15px;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
        
        > a {
          cursor: pointer;
        }
      }

      > span {
        display: block;
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 185px;
          margin: 55px auto 0;

          > svg {
            font-size: 28px;

            path {
              color: #fff;
            }

            > path {
              fill: #fff;
            }

            g {
              fill: #fff;
            }

          }
        }
      }


      @media (max-width: 759px) {
        flex-wrap: wrap-reverse;
        text-align: center;
        > div {
          width: 100%;
        }
        > span {
          width: 100%;
        }
      }

      @media (max-width: 400px) {
        grid-gap: 30px;
      }
    }

    > p {
      color: rgba(255, 255, 255, 0.66);
      font-size: 14px;
      font-weight: 400;
      padding: 100px 0 30px 0;
      text-align: center;
      
      > a {
        color: rgba(255, 255, 255, 0.66);
        font-size: 14px;
        &:hover {
          color: #fff;
        }
      }
    }
  }

`


export const Footer = () => {
    const {t} = useTranslation('global')


    return <FooterStyled>
        <Form/>
        <span data-aos="fade-up">
            <Container>
                <div>
                    <h2>{t("contacts.title")}</h2>
                    <p>{t("contacts.data.0")}</p>
                    <p>{t("contacts.data.1")}</p>
                    <a href="mailto:logistics@makolli.tj">Email: logistics@makolli.tj</a>
                    <a href="tel:+992900303030">Tel: +992 900 30 30 30</a>
                </div>
                <span>
                    <img src={logoWhite} alt=""/>
                    <div>
                        <FaFacebook/>
                        <RiInstagramFill/>
                        <FaTelegram/>
                        <FaTwitter/>
                    </div>
                </span>
            </Container>
            <p>{t("footer")}</p>
        </span>
    </FooterStyled>
}

