import React from 'react';
import styled, {css} from "styled-components";
import variable from "./../../style/var.module.scss"


export const H20Styled = styled.h3`
  font-size: 20px;
  line-height: 26px;
  text-align: ${props => props.align ? props.align : 'left'};

  @media(max-width: 564px) {
    font-size: 18px;
    line-height: 24px;
  }
  
  ${props => props.weight && css`
    font-weight: 600;
  `}
  
  ${props => props.greyColor && css`
    color: ${variable.GREY_COLOR};
  `}
`

export const H20 = ({children, ...rest}) => <H20Styled {...rest}>{children}</H20Styled>
