import React from 'react';
import styled from "styled-components";
import {Container} from "./ui/Container";
import {H56} from "./ui/H56";
import variable from './../style/var.module.scss'
import borderImg from './../img/aboutUs/border.svg'
import maps from './../img/aboutUs/maps.png'
import {H20} from "./ui/H20";
import {P18} from "./ui/P18";
import {useTranslation} from "react-i18next";

const AboutUsStyled = styled.div`

  > div {
    padding-top: 40px;
    text-align: center;

    > span {
      display: block;
      height: 6px;
      margin: 20px auto;
      border-radius: 10px;
      width: 107px;
      background: linear-gradient(180deg, ${variable.BLUE_COLOR_TRANSPARENT}, ${variable.BLUE_COLOR});;
    }

    > h3 {
      width: 600px;
      margin: 0 auto;
    }

    > div {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        position: relative;
        padding-top: 6px;
        padding-left: 36px;

        > img {
          position: absolute;
          top: 0;
          left: 0;
        }

        > span {
          > p {
            margin-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 1042px) {
    > div {
      > div {
        display: block;

        > img {
          margin-top: 30px;
        }
      }
    }
  }

  @media (max-width: 708px) {
    > div {
      > h3 {
        width: 100%;
      }
      > div {
        display: block;
        > img {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 400px) {
    > div {
      > h3 {
        width: 100%;
      }
      > div {
        margin-top: 40px;
        > div {
          padding-left: 26px;
          > span {
            > p {
              margin-top: 20px;
            }  
          }
        }
      }
    }
  }
`


export const AboutUs = () => {
    const {t} = useTranslation('global')

    return <AboutUsStyled data-aos="fade-up">
        <Container pTop>
            <H56>{t("aboutUs.title")}</H56>
            <span/>
            <H20 align="center" greyColor>{t("aboutUs.desc")}</H20>
            <div>
                <div>
                    <img src={borderImg} alt=""/>
                    <span>
                        <P18 italic>{t("aboutUs.text1")}</P18>
                        <P18 italic>{t("aboutUs.text2")}</P18>
                    </span>
                </div>
                <img src={maps} alt=""/>
            </div>
        </Container>
    </AboutUsStyled>
}

