import React, {useEffect, useState} from 'react';
import {Container} from "./Container";
import {H56} from "./H56";
import {H20} from "./H20";
import styled from "styled-components";
import variable from './../../style/var.module.scss'
import {Element, scroller} from 'react-scroll'
import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PageStyled = styled.div`
  > div {
    > span {
      display: block;
      height: 6px;
      margin: 20px auto;
      border-radius: 10px;
      width: 107px;
      background: linear-gradient(180deg, ${variable.BLUE_COLOR_TRANSPARENT}, ${variable.BLUE_COLOR});;
    }

    > h3 {
      margin-top: 20px;
      line-height: 36px;
    }

    > div {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      grid-column-gap: 30px;
      grid-row-gap: 20px;

      > a {
        padding: 10px 20px;
        border-radius: 50px;
        background: none;
        border: 2px solid #1A467D;
        cursor: pointer;
        color: #1A467D;
      }
    }
  }
`

const services = [
    {id: 1, name: 'Услуги складирования', path: '/warehousing'},
    {id: 2, name: 'Внутренние автоперевозки', path: '/domestic-trucking'},
    {id: 3, name: 'Международные автоперевозки', path: '/international-trucking'},
    {id: 4, name: 'Мультимодальные перевозки', path: '/multimodal-transportation'},
    {id: 5, name: 'Брокерские услуги', path: '/brokerage'},
    {id: 6, name: 'Технический импорт - экспорт', path: '/technical-import-export'}
]


const Page = ({title, idx}) => {
    const {t} = useTranslation('global')
    const {pathname} = useLocation()

    const styled = {
        background: "#1A467D",
        color: "#fff"
    }

    useEffect(() => {
        scroller.scrollTo("servicesPage", {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }, [pathname])


    return <Element name="servicesPage">
        <PageStyled>
            <Container pTop>
                <H56>{title}</H56>
                <span/>
                <H20 align="center" greyColor>{t(`services.${idx}.desc`)}</H20>
                <div>
                    {services.map((el, idx) => <NavLink key={idx + 1} to={el.path}
                                                        style={pathname === el.path ? styled : {}}>{t(`services.${idx}.title`)}</NavLink>)}
                </div>
            </Container>
        </PageStyled>
    </Element>
}

export default Page;
