import React from 'react';
import {Container} from '../ui/Container';
import styled, {css} from 'styled-components';
import logo from './../../img/logos.png';
import variables from './../../style/var.module.scss';
import {scroller} from "react-scroll";
import {NavLink, useLocation} from "react-router-dom";
import "/node_modules/hamburgers/dist/hamburgers.css";
import {MenuToggle} from "./MenuToggle";
import {useTranslation} from "react-i18next";


const NavbarStyled = styled.div`
  > div {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 52px 0;
      position: relative;
      z-index: 3;

      > a {
        > img {
          height: 55px;
        }  
      }
      

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 50px;

        > li,
        > a {
          color: ${variables.GREY_COLOR};
          cursor: pointer;
          transition: all .3s ease;


          &:hover {
            transition: all .3s ease;
            color: #070D32;
          }
        }
      }

      > p {
        width: 145px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
  @media (max-width: 900px) {
    > div {
      > div {
        padding: 30px 0;
        > div {
          display: none;
        }
        
        
        > span {
          display: flex;
          align-items: center;
          grid-gap: 30px;
          > main {
            display: block;
          }
        }
      }
    }
  }
`;

export const Navbar = () => {
    const {pathname} = useLocation()
    const {t, i18n} = useTranslation('global')

    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }


    return <NavbarStyled>
        <Container>
            <div>
                <a href="http://logistics.makolli.tj">
                    <img src={logo} alt=''/>
                </a>
                <div>
                    <NavLink to="/" style={{color: pathname === '/' ? '#070D32' : ''}}>{t("nav.0")}</NavLink>
                    {pathname === '/' && <>
                        <li onClick={() => scrollTo("about")}>{t("nav.1")}</li>
                        <li onClick={() => scrollTo("services")}>{t("nav.2")}</li>
                    </>}
                    <li onClick={() => scrollTo("contacts")}>{t("nav.3")}</li>
                </div>
                <span>
                     <p style={{cursor: "pointer"}} onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}>{i18n.language === 'ru' ? 'EN' : 'RU'}</p>
                    <MenuToggle scrollTo={scrollTo} />
                </span>
            </div>
        </Container>
    </NavbarStyled>
}

