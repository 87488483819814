import React from 'react';
import styled, {css} from "styled-components";
import variable from "./../../style/var.module.scss"


export const P18Styled = styled.p`
  font-size: 18px;
  line-height: 30px;
  text-align: ${props => props.align ? props.align : 'left'};
  ${props => props.weight && css`
    font-weight: 600;
  `}
  ${props => props.greyColor && css`
    color: ${variable.GREY_COLOR};
  `}
  ${props => props.italic && css`
    font-style: italic;
  `}
`

export const P18 = ({children, ...rest}) => <P18Styled {...rest}>{children}</P18Styled>
