import React from 'react';
import styled from "styled-components";



const H56Styled = styled.h1`
  font-size: 56px;
  font-weight: 700;
  text-align: center;
  
  @media(max-width: 900px) {
    font-size: 36px;
  }

  @media(max-width: 564px) {
    font-size: 28px;
  }
`


export const H56 = ({children, ...rest}) => <H56Styled {...rest}>{children}</H56Styled>

