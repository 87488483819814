import React, {useEffect} from 'react';
import Header from './components/Header/Header';
import {Services} from './components/Services';
import {AboutUs} from "./components/AboutUs";
import {Advantage} from "./components/Advantage";
import {Footer} from "./components/Footer";
import {Element} from 'react-scroll'
import {Redirect, Route, Switch} from "react-router-dom";
import Page from "./components/ui/Page";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useTranslation} from "react-i18next";


const App = () => {
    const {t} = useTranslation('global')

    useEffect(() => {
        AOS.init({
            duration : 800,
            once: true
        })
    }, [])

    return <div>
        <Header/>
        <Switch>
            <Route exact path="/" render={() => <div>
                <Element name="services">
                    <Services/>
                </Element>
                <Element name="about">
                    <AboutUs/>
                </Element>
            </div>}/>
            <Route path="/warehousing" render={() => <Page title={t("services.0.title")} idx={0}/>}/>
            <Route path="/domestic-trucking" render={() => <Page title={t("services.1.title")} idx={1}/>}/>
            <Route path="/international-trucking" render={() => <Page title={t("services.2.title")} idx={2}/>}/>
            <Route path="/multimodal-transportation" render={() => <Page title={t("services.3.title")} idx={3}/>}/>
            <Route path="/brokerage" render={() => <Page title={t("services.4.title")} idx={4}/>}/>
            <Route path="/technical-import-export" render={() => <Page title={t("services.5.title")} idx={5}/>}/>
            <Redirect to="/" />
        </Switch>
        <Advantage/>
        <Element name="contacts">
            <Footer/>
        </Element>
    </div>
}

export default App;
