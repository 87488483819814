import React from 'react';
import styled from "styled-components";
import {Container} from "./ui/Container";
import img1 from "./../img/advantage/img1.png"
import img2 from "./../img/advantage/img2.png"
import img3 from "./../img/advantage/img3.png"
import variable from './../style/var.module.scss'
import {P18} from "./ui/P18";
import {H20} from "./ui/H20";
import {useTranslation} from "react-i18next";


const AdvantageStyled = styled.div`
  background: rgba(241, 247, 252, 0.78);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: grid;
      grid-template-columns: 305px 345px 305px;
      align-items: center;
      grid-gap: 49px;

      > div {
        padding: 30px;
        border-radius: 20px;
        background: #fff;
        box-shadow: 0 24px 106px 0 rgba(26, 70, 125, 0.17);

        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 90px;
          border-radius: 50%;
          margin: 0 auto;
          border: 3px solid ${variable.BLUE_COLOR};
        }

        > h3 {
          margin-top: 20px;
        }

        > p {
          margin-top: 20px;
        }
      }

      @media (max-width: 1144px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1012px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: 400px) {
        grid-gap: 40px;
      }
    }
  }
`


const advantage = [
    {
        id: 1,
        icon: img1,
        title: 'Прозрачное ценообразование',
        desc: 'Гибкие, доступные цены и лояльный подход к клиентам'
    },
    {
        id: 2,
        icon: img2,
        title: 'Гарантии и безопасный грузов',
        desc: 'Полный контроль и сохранность грузов на всех стадиях транспортировки. Страхование грузов от любых рисков'
    },
    {
        id: 3,
        icon: img3,
        title: '24/7 Поддержка',
        desc: 'Быстрая обработка запросов клиентов и предоставление оптимальной схемы доставки груза'
    }
]


export const Advantage = () => {
    const {t} = useTranslation('global')

    return <AdvantageStyled data-aos="fade-up">
        <Container pTop>
            <div>
                {advantage.map((el, idx) => <div key={idx + 1}>
                    <span>
                        <img src={el.icon} alt=""/>
                    </span>
                    <H20 align="center" weight>{t(`advantage.${idx}.title`)}</H20>
                    <P18 align="center" greyColor>{t(`advantage.${idx}.desc`)}</P18>
                </div>)}
            </div>
        </Container>
    </AdvantageStyled>
}

