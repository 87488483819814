import React from 'react';
import styled from "styled-components";


const CardStyled = styled.div`
  border-radius: 20px;
  box-shadow: 0 24px 106px 0 rgba(26, 70, 125, 0.17);
  background: #fff;
  padding: ${props => props.padding ? props.padding : '40px'};
  
  @media(max-width: 564px) {
    padding: 30px;
  }
`

export const Card = ({children, ...rest}) => {
    return <CardStyled {...rest}>
        {children}
    </CardStyled>
}

