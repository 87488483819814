import "/node_modules/hamburgers/dist/hamburgers.css";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import variable from './../../style/var.module.scss'
import {useTranslation} from "react-i18next";

const MenuToggleStyled = styled.main`
  display: none;
  position: relative;
    > button {
      background: none;
      border: none;
      outline: none;
      > span {
        pointer-events: none;
      }
    }
  
    > div {
      position: absolute;
      background: #fff;
      padding: 20px;
      right: 0;
      margin-top: 10px;
      border-radius: 20px;
      box-shadow: 0 24px 106px 0 rgb(26 70 125 / 37%);
      min-width: 150px;
      > a, > li {
        color: ${variable.GREY_COLOR};
        cursor: pointer;
        &:active {
          color: #070D32;
        }
      }
      
      > li {
        margin-top: 9px;
      }
    }
`


export const MenuToggle = ({scrollTo}) => {
    const {t} = useTranslation('global')

    const [isToggle, setIsToggle] = useState(false)
    const {pathname} = useLocation()

    useEffect(() => {
        window.addEventListener('click', (evt) => {
            if (!evt.target.matches(`.menuBurgerLabel`)) {
                setIsToggle(false)
            }
        })
    }, [])



    return <MenuToggleStyled>
        <button
            onClick={() => setIsToggle(prev => !prev)}
            className={`menuBurgerLabel hamburger--3dy ${isToggle ? "is-active" : ''}`}
            type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"/>
          </span>
        </button>

        {isToggle &&
            <div>
                <NavLink to="/" style={{color: pathname === '/' ? '#070D32' : ''}}>{t("nav.0")}</NavLink>
                {pathname === '/' && <>
                    <li onClick={() => scrollTo("about")}>{t("nav.1")}</li>
                    <li onClick={() => scrollTo("services")}>{t("nav.2")}</li>
                </>}
                <li onClick={() => scrollTo("contacts")}>{t("nav.3")}</li>
            </div>
        }
    </MenuToggleStyled>
}